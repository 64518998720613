import React from 'react';
import PropTypes from 'prop-types';
import { StyledSpin } from './Loader.style';

export const Loader = ({ message }) => <StyledSpin size='large' tip={message || 'Loading...'} />;

Loader.propTypes = {
  message: PropTypes.string
};

// import React from 'react';
// import { Spin } from 'syngenta-digital-cropwise-react-ui-kit';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// const StyledSpin = styled(Spin)`
//   right: 0;
//   left: 0;
//   top: 50%;
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   background: #222222ab;
//   z-index: 9999;
// `;

// export const Loader = ({ message }) => (
//   <div style={{ background: '#252323', height: '100vh' }}>
//     <StyledSpin size='large' tip={message || 'Loading...'} />
//   </div>
// );

// Loader.propTypes = {
//   message: PropTypes.string
// };
